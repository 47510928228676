<template>

    <!-- header -->
    <header class="header-sub header-btl" :style="'z-index : ' + zIndex + '; background-color : ' + bgColor">
        <link rel="stylesheet" href="/assets/css/style.css">
        <template v-if="btnLeft !== undefined">
            <h1 v-if="btnLeft === 'logo'" class="logo"><img src="/assets/images/common/logo.png"
                                                            alt="Masha and the Bear"></h1>
            <button v-if="btnLeft === 'back'" type="button" class="btn btn-back" onclick="history.go(-1)"><span
                class="blind">이전 페이지</span></button>
            <button v-if="btnLeft === 'close'" type="button" class="btn btn-close" @click="close()"><span class="blind">닫기</span>
            </button>
        </template>

        <h2 class="head-tit">
            <span class="page-tit">{{ title }}</span>
        </h2>

        <template v-if="btnRight !== undefined">
            <h1 v-if="btnRight === 'logo'" class="logo"><img src="/assets/images/common/logo.png"
                                                             alt="Masha and the Bear"></h1>
            <button v-if="btnRight === 'back'" type="button" class="btn btn-back" onclick="history.go(-1)"><span
                class="blind">이전 페이지</span></button>
            <button v-if="btnRight === 'close'" type="button" class="btn btn-close" @click="close()"><span
                class="blind">닫기</span></button>
        </template>

    </header>

</template>

<script>

import('@/../public/assets/js/common')

export default {
    name: "layoutHeader",
    props: [
        "title",
        "btnLeft",
        "btnRight",
        "zIndex",
        "bgColor"
    ],
    mounted() {
    },
    methods: {
        close () {

            this.appClose({
                reload: true,
            })

        }
    }
}
</script>

<style scoped>
/*@import '../../assets/css/style.css';*/
</style>