<template>

    <div class="wrap p-email-login">
        <!-- header -->
        <layout-header title="이메일 로그인" btn-left="back"></layout-header>

        <!-- container -->
        <div class="container">
            <div class="content">
                <div class="img-wrap">
                    <img src="/assets/images/member/img_email_login.png" alt="">
                </div>
                <div class="login-form">
                    <form @submit.prevent="login">
                        <p class="guide-text">가입한 정보로 로그인하여 서비스를 시작해 보세요.</p>
                        <fieldset>
                            <legend>이메일 로그인</legend>
                            <div class="form-row">
                                <label for="email">이메일</label>
                                <input type="text" name="" id="email" class="inp-member" v-model="email">
                            </div>
                            <div class="form-row">
                                <label for="password">비밀번호</label>
                                <input type="password" name="" id="password" class="inp-member" placeholder="숫자, 영문 혼합 6자리 이상" v-model="password">
                            </div>
                            <div class="button-area">
                                <button type="submit" class="btn-member">로그인</button>
                            </div>
                        </fieldset>
                        <div class="login-link">
                            <a href="/emailJoin" class="link-join">이메일 회원가입</a>
                            <a href="/findPasswd" class="link-find" target="_blank">비밀번호 찾기</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
	    <div class="fixed-bg"></div>
    </div>

</template>

<script>
import LayoutHeader from "@/pages/layouts/layoutHeader";
export default {
	name: "login",
	components: {LayoutHeader},
	data () {

		return {
            email: '',
            password: '',

		}
	},
	created() {
		this.doLogoutWithOutReload()
	},
	mounted() {
	},
    methods: {

        login() {

            this.doLoginMainProc(this.email, this.password, () => {

                this.setMainSessionKeep(this.isKeepSession, null)

                if (!this.stringUtils.isEmpty(this.$route.query.referrer)) {
                    location.href = this.$route.query.referrer
                } else {
                    location.href = "/"
                }

                // location.href = this.commonUtils.transStringIfNull(this.$route.query.referrer, '/profiles')

            });
        },

        isAvailablePassword() {
            return true
        },

        isAvailableEmail() {
            return true
        }
    },
}
</script>

<style scoped>

</style>